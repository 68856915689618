
Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}

/// date: Date
export function isDateValid(date) {
    try {
        // !valid => getTime === NaN
        // https://www.geeksforgeeks.org/how-to-check-a-date-is-valid-or-not-using-javascript/
        return date.getTime() === date.getTime()
    } catch (e) {
        return false
    }
}

/// dateToFormat: Date
export function formatDateTime(dateToFormat) {
    if(!isDateValid(dateToFormat))
        return "";

    const date = dateToFormat.getDate().pad(2) + '.' + (dateToFormat.getMonth() + 1).pad(2) + '.' + dateToFormat.getFullYear();
    const time = dateToFormat.getHours().pad(2) + ":" + dateToFormat.getMinutes().pad(2) + ":" + dateToFormat.getSeconds().pad(2);
    return time + ' ' + date;
}

/// dateToFormat: Date
export function formatDate(dateToFormat) {
    if(!isDateValid(dateToFormat))
        return "";

    return dateToFormat.getDate().pad(2) + '.' + (dateToFormat.getMonth() + 1).pad(2) + '.' + dateToFormat.getFullYear();
}

export default {
    isDateValid,
    formatDate,
    formatDateTime
};
