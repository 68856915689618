<script setup>
import { useStoreMetaData } from '@/stores/storeMetaData'
import {computed, inject, ref, watch} from "vue";
import timeTools from "@/lib/es-bill-shared-js/misc/timeTools";
import edaTools from "@/lib/es-bill-shared-js/misc/edaTools";
import { useFrontEndStore } from "@/stores/frontEndStore";
import { getResponseInfoCustomerProcesses } from "@/common/responseCodeResolver";
import router from "@/router/routerSetup"; // uncommon
import {useI18n} from "vue-i18n";
import DialogError from "@/components/DialogError.vue";
import { startEnergyDataRequest } from '@/services/meteringPointMembershipService';

const metaDataStore = useStoreMetaData();
const { t } = useI18n();
const $log = inject('log');

// kinda tricky:
// this view is used as standalone view used by router and also as sub view,
// so the defaults are for the standalone screen
const props = defineProps({
  showHeader: { type: Boolean, required: false, default: true },

  // fixed filters to
  clientCodeFilter: { type: String, required: false },
  //ecidFilter: { type: String, required: false }, // @todo ecid aware

  overwriteFilterAndPaging: {
    type: Object,
    default(rawProps) { return useFrontEndStore().pageSettingsFilter.viewMeteringPoints },
    required: false
  },
  overwriteFilterCompany: {
    type: Object,
    default(rawProps) { return useFrontEndStore().communityCompanyFilter },
    required: false
  },

})

const filterPaging = ref(props.overwriteFilterAndPaging);
const filterCompany = ref(props.overwriteFilterCompany);

function getMembershipsExtended() {
  let c = [];

  for (const mpm of metaDataStore.allMeteringPointMemberships) {
    const community = metaDataStore.getCommunity(mpm.ecn);
    const communityCompany = metaDataStore.getCommunityCompany(mpm.ecn);

    const ds = timeTools.formatDate(mpm.dateStart)
    const de = timeTools.formatDate(mpm.dateEnd);
    let dSpan = []
    if(ds.length)
      dSpan.push(ds)
    if(de.length)
      dSpan.push(ds)

    c.push({
      ...mpm,
      memberAssociated: metaDataStore.getCommunityMember(mpm.ecn, mpm.clientCode),
      meteringPointShort: edaTools.shortenIdentifiersLong(mpm.meteringPoint),
      meteringpointDirectionDisplay: mpm.meteringPointDirection === "GENERATION" ? t("producer") : t("consumer"),
      community: community,
      communityCompany: communityCompany,
      member: mpm.clientCode.length ? communityCompany.members.find(m => m.clientCode === mpm.clientCode) : null,
      dateStartDisplay: ds,
      dateEndDisplay: de,
      dateSpan: dSpan.length ? dSpan.join(` ${t('to')} `) : "",
    });
  }

  if (props.clientCodeFilter) {
    c = c.filter(p => p.clientCode === props.clientCodeFilter);
  }

  return c;
}

const membershipsDisplay = computed(() => {
  const ecnList = filterCompany.value.ecnList;
  const status = filterPaging.value.status;

  $log.debug("membershipsDisplay update I",ecnList,status)

  const mpms = getMembershipsExtended().filter(mpm =>
    (
      ecnList.length === 0 ||
      ecnList.includes(mpm.ecn)
    ) && (
      status.length === 0 ||
      status.includes(mpm.membershipStatus)
    )
  );

  //$log.debug("membershipsDisplay update ",mpms)
  // console.trace();
  return mpms;
})

const tableHeader = computed(() => {
  let cols = [];
  cols.push({ title: t('description'), value: 'nameFriendly', key: 'nameFriendly' })

  if (filterCompany.value.ecnList.length !== 1)
    cols.push({ title: t('community'), value: 'communityCompany.name', key: 'communityCompany.name'})

  if (!props.clientCodeFilter) {
    cols.push({ title: t('member'), value: 'memberAssociated.nameFriendly', key: 'memberAssociated.nameFriendly'})
  }

  cols.push({ title: t('meteringPointNumber'), value: 'meteringPointShort', key: 'meteringPointShort'})
  cols.push({ title: t('direction'), value: 'meteringpointDirectionDisplay', key: 'meteringpointDirectionDisplay' })
  cols.push({ title: t('status'), value: 'membershipStatus', key: 'membershipStatus'})
  cols.push({ title: t('from'), value: 'dateStartDisplay', key: 'dateStartDisplay'})
  cols.push({ title: t('to'), value: 'dateEndDisplay', key: 'dateEndDisplay' })
  cols.push({ title: t('actions'), key: 'actions', sortable: false, align: 'd-none' });

  cols = cols.concat([
    { text: 'clientCode', value: 'clientCode', align: ' d-none' }, // ' d-none' hides the column but keeps the search ability
    { text: 'meteringPoint', value: 'meteringPoint', align: ' d-none' },
  ])

  return cols;
});

async function handleRowClick(event, row) {
  // await router.push({ name: 'ViewMeteringpointMembership', params: { mpmObjectId: row.item._id } })

}
async function handleCardClick(item, event) {
  // await router.push({ name: 'ViewMeteringpointMembership', params: { mpmObjectId: item._id } })
}

const communityAvailable = computed(() => {
  const cs = metaDataStore.allCommunityCompanies;

  return cs.map(c => {
    return {
      title: c.name,
      props: { subtitle: c.ecn },
      ecn: c.ecn,
    };
  })

})


const statusAvailable = ref([
  { title: t("created"), value: "CREATED" },
  { title: t("started"), value: "STARTED" },
  { title: t("declined"), value: "DECLINED" },
  { title: t("waitingForUser"), value: "WAITING_FOR_USER" },
  { title: t("accepted"), value: "ACCEPTED" },
  { title: t("active"), value: "ACTIVE" },
  { title: t("ending"), value: "ENDING" },
  { title: t("ended"), value: "ENDED" },
]);

function getChipColor(v) {
  if (v === "CREATED")
    return "#1C7BCB";
  if (v === "STARTED")
    return "orange"; // FAC934 fde59a
  if (v === "DECLINED")
    return "red";
  if (v === "WAITING_FOR_USER")
    return "orange";
  if (v === "ACCEPTED")
    return "orange";
  if (v === "ACTIVE")
    return "#459506";
  if (v === "ENDING")
    return "#459506";
  if (v === "ENDED")
    return "#333333";

  return "#333333";
}

function getChipText(v) {
  if (v === "CREATED")
    return t("created");
  if (v === "STARTED")
    return t("started");
  if (v === "DECLINED")
    return t("declined");
  if (v === "WAITING_FOR_USER")
    return t("waitingForUser");
  if (v === "ACCEPTED")
    return t("accepted");
  if (v === "ACTIVE")
    return t("active");
  if (v === "ENDING")
    return t("ending");
  if (v === "ENDED")
    return t("ended");

  return v;
}

function getChipTooltip(v, mpm) {
  if (v === "CREATED")
    return t("createdTooltip");
  if (v === "STARTED")
    return t("startedTooltip");
  if (v === "DECLINED") {
    let msg = t("declinedTooltip");
    if (mpm?.reasonDeclined === 'DECLINED_DSO')
      msg += t("declinedDsoReason");
    if (mpm?.reasonDeclined === 'DECLINED_USER')
      msg += t("declinedUserReason");
    if (mpm?.reasonDeclined === 'TIMEOUT_USER')
      msg += t("timeoutUserReason");
    return msg;
  }
  if (v === "WAITING_FOR_USER")
    return t("waitingForUserTooltip");
  if (v === "ACCEPTED")
    return t("acceptedTooltip");
  if (v === "ACTIVE")
    return t("activeTooltip");
  if (v === "ENDING")
    return t("endingTooltip");
  if (v === "ENDED")
    return t("endedTooltip");

  return v;
}

function copyText(txt) {
  navigator.clipboard.writeText(txt);
}

const overlayInfoActive = ref(false);
const overlayInfoMessage = ref("");

function showDetails(mpm) {
  if (!mpm || mpm.membershipStatus !== "DECLINED")
    return false;

  let msg = t("declinedTooltipDetailed");
  if (mpm?.reasonDeclined === 'DECLINED_DSO')
    msg += t("declinedDsoReason") + "<br>";
  if (mpm?.reasonDeclined === 'DECLINED_USER')
    msg += t("declinedUserReason") + "<br>";
  if (mpm?.reasonDeclined === 'TIMEOUT_USER')
    msg += t("timeoutUserReason") + "<br>";

  msg += t("detailInfo") + "<br>";
  for (const responseCode of mpm.responseCodes) {
    msg += responseCode + ": " + getResponseInfoCustomerProcesses(responseCode).description + "<br>";
  }

  overlayInfoMessage.value = msg;
  overlayInfoActive.value = true
  return true;
}

// Checkbox implementation
/**
 * Watch checkboxes and log selected metering points id's.
 *
 * @type {ref<[]>}
 */
const selectedMeteringPoints = ref([]);
watch(() => selectedMeteringPoints.value, (newValue, oldValue) => {
  $log.debug('Selected metering points:', newValue);
});

// Button implementation
const selectDateDialog = ref(false); // True opens select-date dialog
const dialogError = ref(null);

/**
 * Get energy data button process.
 *
 * Check at least one metering point is selected.
 * Start select date dialog.
 * If no meter point is selected use overlay to show error message.
 *
 * @returns {void}
 */
async function buttonGetEnergyData() {
  if (selectedMeteringPoints.value.length === 0) {
    await dialogError.value.showDialog(t('getEnergyData.noMpError'), t('getEnergyData.noMeterPoint'));
  }
  else {
    selectDateDialog.value = !selectDateDialog.value;
  }
}

// Date Picker
const energyDataDateRange = ref(null);
const getEnergyDataStartDate = computed(() => {
  if (!energyDataDateRange.value) return null;
  else
    return new Date(Math.min(...energyDataDateRange.value));
})
const getEnergyDataEndDate = computed(() => {
  if (!energyDataDateRange.value) return null;
  else
    return new Date(Math.max(...energyDataDateRange.value));
})

/**
 * Cancel button process on select date dialog.
 *
 * Clear date variables when cancel button in dialog is used.
 *
 * @returns {void}
 */
function cancelSelectDateDialog() {
  selectDateDialog.value = false;
  energyDataDateRange.value = null;
}

/**
 * Check date range, start get data process.
 *
 * Check if end date is after start date, if not use overlay
 * to show error message.
 * Open data summary.
 *
 *
 * @returns {void}
 */
async function confirmGetEnergyDates() {
  // Check dates selected
  if (energyDataDateRange.value === null || energyDataDateRange.value.length < 2) {
    await dialogError.value.showDialog(t('getEnergyData.dateError'), t('getEnergyData.noDate'));
  }
  // Dates in future not allowed
  else if (getEnergyDataStartDate.value > new Date() || getEnergyDataEndDate.value > new Date()) {
    await dialogError.value.showDialog(t('getEnergyData.dateError'), t('getEnergyData.futureDate'));
  }
  else {
    // Confirm dates show summary
    selectDateDialog.value = false;
    confirmGetEnergyDataDialog.value = true;
  }
}

/**
 * Assign metering points membership data to selected metering points.
 *
 * @returns {Object}
 *  - meteringPointsData: {Array} - Data for selected metering points.
 *  - dateStart: {string} - Start date for energy data, from date picker.
 *  - dateEnd: {string} - End date for energy data, from date picker.
 */
const requestEnergyData = computed( () => {
  const requestData = {
    meteringPointsData: [],
    dateStart: getEnergyDataStartDate.value,
    dateEnd: getEnergyDataEndDate.value
  }
  requestData.meteringPointsData = getMembershipsExtended().filter(mpm =>
    selectedMeteringPoints.value.includes(mpm.mpmObjectId)
  )
  return requestData;
  }
)

// Confirm Dialog
const confirmGetEnergyDataDialog = ref(false);

/**
 * Return unique communities for selected metering points.
 *
 * @param {Object} requestEnergyData - Selected metering points and start/end dates from date picker.
 * @returns {Array} - Unique communities for selected metering points.
 */
 function uniqueECN(requestEnergyData) {
  const ecnList = [];
  for (const mp of requestEnergyData.meteringPointsData) {
    if (!ecnList.includes(mp.ecn)) {
      ecnList.push(mp.ecn);
    }
  }
  return ecnList;

}

/**
 * Format start date for energy data summary.
 *
 * Date format: dd-mm-yyyy
 *
 * @type {ComputedRef<string>}
 */
const formattedGetEnergyStartDate = computed(() => {
  if (!getEnergyDataStartDate.value) return null;
  const date = new Date(getEnergyDataStartDate.value);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
  }
);

/**
 * Format end date for energy data summary.
 *
 * Date format: dd-mm-yyyy
 *
 * @type {ComputedRef<string>}
 */
const formattedGetEnergyEndDate = computed(() => {
  if (!getEnergyDataEndDate.value) return null;
  const date = new Date(getEnergyDataEndDate.value);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
  }
);

/**
 * Cancel button process on confirm data dialog.
 *
 * Close dialogs, clear date variables.
 *
 * @returns {void}
 */
function cancelGetEnergyData() {
  confirmGetEnergyDataDialog.value = false;
  energyDataDateRange.value = null;
  selectDateDialog.value = false;
}

/**
 * Start get energy data process.
 *
 * @returns {void}
 */
async function confirmGetEnergyData() {
  // Close dialog
  confirmGetEnergyDataDialog.value = false;
  // Start service
  const callsResult = await startEnergyDataRequest(requestEnergyData.value);
  $log.debug('confirmGetEnergyData call stats', callsResult);

  /// @todo display stats if succeeded is not calles wanted?
}

</script>

<template>
  <v-container class="pa-0">

    <!-- Error dialog from components -->
    <dialog-error ref="dialogError" />

    <p v-if="props.showHeader" class="textHeadline">{{ t('meteringPoints') }}</p>

    <v-dialog v-model="overlayInfoActive" width="auto">
      <v-card max-width="400" :title="t('details')">
        <v-card-text v-html="overlayInfoMessage"></v-card-text>
        <template v-slot:actions>
          <v-btn class="ms-auto" @click="overlayInfoActive = false">{{ t('ok') }}</v-btn>
        </template>
      </v-card>
    </v-dialog>

    <!--    :custom-filter="filterCommunities"-->
    <v-row class="filterRow d-flex justify-lg-space-between" :class="{'mx-5': props.clientCodeFilter && $vuetify.display.xs}" no-gutters>
      <v-col v-if="!props.clientCodeFilter" cols="12" sm="6">
        <v-autocomplete bg-color="white" :class="{'mr-0': $vuetify.display.xs}"  hide-details="auto" :label="t('community')" v-model="filterCompany.ecnList"
          :items="communityAvailable" :filter-keys="['title', 'value']" multiple variant="outlined" item-value="ecn"
          clearable></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
        <v-autocomplete bg-color="white" :class="{'mr-0': $vuetify.display.xs}" :label="t('status')" hide-details="auto"
                        v-model="filterPaging.status" :items="statusAvailable"
                        :filter-keys="['title', 'value']" multiple variant="outlined" clearable></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field bg-color="white" :class="{'mr-0': $vuetify.display.xs}" hide-details="auto" :label="t('descriptionOrMemberOrMeteringPoint')"
        v-model="filterPaging.search" variant="outlined"></v-text-field>
      </v-col>
      <!--      Get Energy Data Button-->
      <v-col cols="6" class="d-flex justify-end">
        <v-btn rounded="xs" size="x-large" @click="buttonGetEnergyData">{{ t('getEnergyData.buttonLabel') }}</v-btn>
      </v-col>
    </v-row>

    <!--    Get Energy Data Dates Dialog-->
    <v-dialog v-model="selectDateDialog" persistent max-width="400px">
      <v-card>
        <v-card-title>{{ t('getEnergyData.dateRangeDialog') }}</v-card-title>
        <v-card-text>
          <v-date-picker
            v-model="energyDataDateRange"
            show-adjacent-months
            :title="t('getEnergyData.startDate')"
            width="350"
            multiple='range'>
          </v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancelSelectDateDialog">{{ t('cancel') }}</v-btn>
          <v-btn
            @click="confirmGetEnergyDates">{{ t('confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    Energy Data summary and confirm -->
    <v-dialog v-model="confirmGetEnergyDataDialog" max-width="450">
      <v-card>
        <v-card-title> {{ t('getEnergyData.requestSummary') }} </v-card-title>
        <v-card-text>
              <p>{{ t('getEnergyData.startDate') }}: {{ formattedGetEnergyStartDate }}</p>
              <p>{{ t('getEnergyData.endDate') }}: {{ formattedGetEnergyEndDate }}</p>
              <br></br>
              <p>{{ t('getEnergyData.communitiesSelected') }}</p>
              <ul>
                <li v-for="ec in uniqueECN(requestEnergyData)">{{ ec }}</li>
              </ul>
              <br></br>
              <p><u>{{ t('community') }} - {{ t('meteringPoints') }}:</u></p>
              <ul>
                <li v-for="mp in requestEnergyData.meteringPointsData">{{ mp.ecn }} - {{ mp.meteringPointShort }}</li>
              </ul>
        </v-card-text>
        <v-card-actions>
              <v-btn @click="cancelGetEnergyData">{{  t('cancel')  }}</v-btn>
              <v-btn @click="confirmGetEnergyData">{{  t('confirm')  }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-container class="mainViewContent" :class="{'pa-0 bg-transparent': !props.clientCodeFilter && $vuetify.display.xs}">

      <v-data-table @click:row="handleRowClick"
                    class="hidden-sm-and-down"
                    mobile-breakpoint="sm"
                    :headers="tableHeader"
                    :items="membershipsDisplay"
                    :search="filterPaging.search"
                    v-model:page="filterPaging.page"
                    v-model:items-per-page="filterPaging.itemsPerPage"
                    item-value="mpmObjectId"
                    show-select
                    v-model="selectedMeteringPoints">
        <template v-slot:item.membershipStatus="{ item, value }">
          <v-chip :color="getChipColor(value)" size="small" variant="tonal" @click.stop="showDetails(item)">
            {{ getChipText(value) }}
            <v-tooltip style="color: white" activator="parent" location="start">
              <p>{{ getChipTooltip(value, item) }}</p>
            </v-tooltip>
          </v-chip>
        </template>
        <template v-slot:item.meteringPointShort="{ item, value }">
          <span @click.stop="copyText(item.meteringPoint)" class="hovercell">
            {{ value }}
            <v-icon size="tiny" :title="t('clickToCopy')" class="hovercopy hoverpos">mdi-content-copy</v-icon>
          </span>
        </template>
      </v-data-table>

      <v-data-table class="hidden-md-and-up bg-transparent"
                    :headers="tableHeader"
                    :items="membershipsDisplay"
                    :search="filterPaging.search"
                    v-model:page="filterPaging.page"
                    v-model:items-per-page="filterPaging.itemsPerPage">
        <template v-slot:headers="{ item }"></template>
        <template v-slot:item="{ item }">
          <v-card @click="handleCardClick(item)" elevation="2"
            class="mb-3 mt-2 ml-2 mr-2" min-height="110">
            <v-row class="pt-2 pl-2 h-40" no-gutters>
              <v-col cols="1">
                <v-checkbox v-model="selectedMeteringPoints" :value="item.mpmObjectId"></v-checkbox>
              </v-col>
              <v-col cols="8" class="font-weight-medium">{{ item.nameFriendly }}</v-col>
              <v-col cols="3" class=" text-right pr-3">
                <v-chip :color="getChipColor(item.membershipStatus)" size="small" variant="tonal" >
                  {{ getChipText(item.membershipStatus) }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row class="pt-2 pl-2 h-40" no-gutters>
              <v-col cols="9" class=" font-weight-medium">{{ item.memberAssociated?item.memberAssociated.nameFriendly:"" }}</v-col>
              <v-col cols="3" class=" text-right pr-3">
                {{ item.meteringpointDirectionDisplay }}
              </v-col>
            </v-row>
            <v-row class="pl-2 h-40 pt-3" no-gutters>
              <v-col cols="7">
                  <span @click.stop="copyText(item.meteringPoint)" class="hovercell">
                    {{ item.meteringPointShort }}
                    <v-icon size="tiny" :title="t('clickToCopy')" class="hovercopy hoverpos">mdi-content-copy</v-icon>
                  </span>
              </v-col>
              <v-col cols="5" class="text-right pr-2 pb-2">
                {{ item.dateSpan }}
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>


<style scoped>

.v-data-table :deep(tr.v-data-table__tr:hover) {
  background: #DEECF7 !important;
}

.filterRow {
  margin: 16px 0;
}

</style>
