import {defineStore} from 'pinia'
import {computed, inject, ref} from "vue";
import axios from "axios";
import {BACKEND_BASEURL} from "@/config/config";
import { useSnackbarStore } from "@es-bill/es-bill-common-components";
import { useI18n } from "vue-i18n";


export const useStoreMetaData
  = defineStore('storeMetaData', () => {
  const { t } = useI18n()
  const $log = inject('log');
  const snackbarStore = useSnackbarStore();

  // state
  const metaDataLoaded = ref(false);
  const allMetaDataLoaded =
    computed(() => metaDataLoaded.value);

  function setAllMetaDataLoaded(loadedState) {
    metaDataLoaded.value = loadedState;
  }

  const communities = ref([]);
  const communitiesLoading = ref(false);
  const allCommunities =
    computed(() => communities.value);
  const allCommunitiesLoading =
    computed(() => communitiesLoading.value);

  const communityCompanies = ref([]);
  const communityCompaniesLoading = ref(false);
  const allCommunityCompanies =
    computed(() => communityCompanies.value);
  const allCommunityCompaniesLoading =
    computed(() => communityCompaniesLoading.value);

  const meteringPointMemberships = ref([]);
  const meteringPointMembershipsLoading = ref(false);
  const allMeteringPointMemberships =
    computed(() => meteringPointMemberships.value);
  const allMeteringPointMembershipsLoading =
    computed(() => meteringPointMembershipsLoading.value);



  /// @todo add ecid on nav rework
  function getCommunity(ecn) {
    const cs = communities.value.filter(community => community.ecn === ecn);

    if (cs.length > 0)
      return cs[0];

    return {
      id: 0,
      name: "dlt",
      legal: "dlt",
    }
  }

  function getCommunityCompany(ecn) {
    const cs = communityCompanies.value.filter(community => community.ecn === ecn);

    if (cs.length > 0)
      return cs[0];

    return {
      id: 0,
      name: "dlt",
      legal: "dlt",
      members: [],
    }
  }

  // member with clientCode within ecn
  function getCommunityMember(ecn, clientCode) {
    const community = getCommunityCompany(ecn);
    if (community.ecn === ecn) {
      const members = community.members.filter(
        member => member.clientCode === clientCode);
      if (members.length)
        return members[0];
    }

    return null
  }

  function getMeteringPointMemberships(mpmObjectId) {
    let mpms = meteringPointMemberships.value.filter(
      mpm => mpm._id === mpmObjectId);
    if (mpms.length > 1)
      $log.warn("too many memberships for given ID, selecting first");
    if (mpms.length >= 1)
      return mpms[0];

    return null;
  }

  function getMeteringPointMembershipsForClient(client) {
    if (client?.clientCode?.length)
      return meteringPointMemberships.value.filter(
        mpm => mpm.clientCode === client.clientCode);

    return []; // no client or no client code
  }

  async function fetchCommunities() {
    return new Promise((resolve, reject) => {
      communitiesLoading.value = true;
      communities.value = [];

      axios.get(BACKEND_BASEURL + 'metadata/communities').then(response => {
        $log.debug(response.data);
        communities.value = response.data;
        communitiesLoading.value = false;
        resolve(response.data)

      }).catch(error => {
        $log.warn(error);
        snackbarStore.showSnackbar(t('connectionProblem'),"", "Warning", 10000, []);
        reject(error)
      })
    });
  }

  async function fetchCommunityCompanies() {
    return new Promise((resolve, reject) => {
      communityCompaniesLoading.value = true;
      communityCompanies.value = [];

      axios.get(BACKEND_BASEURL + 'metadata/companies').then(response => {
        $log.debug(response.data);
        communityCompanies.value = response.data;
        communityCompaniesLoading.value = false;
        resolve(response.data)

      }).catch(error => {
        $log.warn(error);
        snackbarStore.showSnackbar(t('connectionProblem'),"", "Warning", 10000, []);
        reject(error)
      })
    });
  }

  async function fetchMeteringPointMemberships() {
    return new Promise((resolve, reject) => {
      meteringPointMembershipsLoading.value = true;
      meteringPointMemberships.value = [];

      axios.get(BACKEND_BASEURL + 'metadata/memberships').then(response => {
        $log.debug(response.data);
        meteringPointMemberships.value =
          response.data.map(mpm => {
            return {
              ...mpm,
              mpmObjectId: mpm._id,
              dateStart: new Date(mpm.dateStart),
              dateEnd: (mpm.dateEnd?new Date(mpm.dateEnd):mpm.dateEnd),
            }
          });
        meteringPointMembershipsLoading.value = false;
        resolve(response.data)

      }).catch(error => {
        $log.warn(error);
        snackbarStore.showSnackbar(t('connectionProblem'),"", "Warning", 10000, []);
        reject(error)
      })
    });
  }



  // todo how to provide errors to the user?
  async function updateAllMetaData() {
    setAllMetaDataLoaded(false);

    return Promise.all([
      fetchCommunities(),
      fetchCommunityCompanies(),
      fetchMeteringPointMemberships(),
      // applicantStore.fetchApplicants(),
    ]).then(results => {
      $log.debug("all meta data loaded")
      setAllMetaDataLoaded(true);
    }).catch(error => {
      // output at individual request
      throw error; // propagate
    })
  }


  return {
    allMetaDataLoaded,
    setAllMetaDataLoaded,

    allCommunities,
    allCommunitiesLoading,

    allCommunityCompanies,
    allCommunityCompaniesLoading,

    allMeteringPointMemberships,
    allMeteringPointMembershipsLoading,

    getCommunity,
    getCommunityCompany,
    getCommunityMember,
    getMeteringPointMemberships,
    getMeteringPointMembershipsForClient,

    fetchCommunities,
    fetchCommunityCompanies,
    fetchMeteringPointMemberships,

    updateAllMetaData,
  }

})
