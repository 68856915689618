// axios-request-interceptor.js

// update token automatically if needed and sets it

import updateToken from '@/middleware/token-update';
import axios from 'axios'
import {BACKEND_BASEURL} from "@/config/config";

export default function setup(app) {
    axios.interceptors.request.use(async request => {
        // https://blog.bitsrc.io/setting-up-axios-interceptors-for-all-http-calls-in-an-application-71bc2c636e4e
        // request.url can be used to be selective about updating the request

        // set our JWT token only when requesting data from our configured backend
        if(request.url.startsWith(BACKEND_BASEURL)) {
            const token = await updateToken(app);
            request.headers['Authorization'] = `Bearer ${token}`;
        }

        return request;
    });
}
