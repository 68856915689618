let frontendBase  = import.meta.env.VITE_FRONTEND_BASEURL || "http://localhost:8080/";
let backendBase   = import.meta.env.VITE_BACKEND_BASEURL ||  "http://localhost:9000/";


if(!frontendBase.endsWith('/'))
  frontendBase+='/';
if(!backendBase.endsWith('/'))
  backendBase+='/';

export const FRONTEND_BASEURL = frontendBase;
export const BACKEND_BASEURL = backendBase;
export const VERSION_IDENTIFIER = import.meta.env.VITE_VERSION_IDENTIFIER || "00.1000";

