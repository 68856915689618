import {createApp, markRaw} from 'vue'

// Plugins
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

// afaik middleware currently need the app (Vue3 instance) for referencing "globals", since this. is only valid
// in components
import updateToken from '@/middleware/token-update';
import axiosSetup from '@/middleware/axios-request-interceptor';
import {logger,  loggerOptions} from "@/plugins/logging-plugin";

// Services
import meteringPointMembershipService from '@/services/meteringPointMembershipService';

import App from './App.vue'
const app = createApp(App);
axiosSetup(app);
app.use(logger,loggerOptions);
pinia.use(({ store }) => {
  store.$log = markRaw(app.config.globalProperties.$log);
});

app.$log.info("bootstrapping app");

// register plugins
import { configureVuetify } from '@/plugins/vuetifyConf'
import kc from '@/plugins/keycloak-plugin';

configureVuetify(app)
app.use(kc);
app.use(pinia)

import i18n from "@/plugins/i18n";
app.use(i18n);

// register services
app.use(meteringPointMembershipService);

// provide global access
app.provide('log',     markRaw(app.$log))
app.provide('logger',  markRaw(app.$log))
app.config.globalProperties.$app=app;
app.provide('$app',app);

app.$log.info("setting up kc")
// keycloak and rest of setup
import router from './router/routerSetup' // after store (pinia)
app.$keycloak
    .init({
        onLoad: 'login-required',
        //onLoad: 'check-sso',

        checkLoginIframe: false
    })
    .then((authenticated) => {

        app.$log.info("starting app, authenticated:"+authenticated);

        if(!authenticated) {
            window.location.reload();
            //console.error("avoided reload")
            return;
        }

      app.use(router)
      app.mount('#app')



      // access token is refreshed (if needed) on nav change @App.vue
        // when axios performs a request
        // on window focus:
        window.onfocus = () => {
            updateToken(app);
        };

        // also the access token update can/could be triggered periodically
        // but this is without user interaction
        setInterval(updateToken, 240000, app);
    });

