import {defineStore} from "pinia";
import axios from "axios";
import {BACKEND_BASEURL} from "@/config/config";

export class DsoContract {
  constructor(name, ecn, ecid, nameDso, ecnDso, topology, portal) {
    this.name = name;
    this.ecn = ecn;
    this.ecid = ecid;
    this.nameDso = nameDso;
    this.ecnDso = ecnDso;
    this.topology = topology;
    this.portal = portal;
  }
}

/**
 * Contract store
 * Uniquely identifies contract by _id from MongoDB
 */
export const useDsoContractStore = defineStore('contracts', {
  state: () => ({
    contracts: [],
  }),
  actions: {
    async fetchContracts() {
      try {
        const response = await axios.get(BACKEND_BASEURL + 'contracts/all');
        this.contracts = response.data;
      } catch (error) {
        console.error('Failed to fetch contracts:', error);
      }
    },

    async getContractById(contractId) {
      try {
        const response = await axios.get(BACKEND_BASEURL + `contracts/${contractId}`);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch contract:', error);
      }
    },

    async createContract(newContract) {
      try {
        const response = await axios.post(BACKEND_BASEURL + 'contracts', newContract);
        this.contracts.push(response.data);
      } catch (error) {
        console.error('Failed to create contract:', error);
      }
    },

    async updateContract(contractId, updatedContract) {
      try {
        const response = await axios.put(BACKEND_BASEURL + `contracts/${contractId}`, updatedContract);
        const index = this.contracts.findIndex(c => c._id === contractId);
        if (index !== -1) {
          this.contracts[index] = response.data;
        }
      } catch (error) {
        console.error('Failed to update contract:', error);
      }
    },

    async deleteContract(contractId) {
      try {
        await axios.delete(BACKEND_BASEURL + `contracts/${contractId}`);
        this.contracts = this.contracts.filter(c => c._id !== contractId);
      } catch (error) {
        console.error('Failed to delete contract:', error);
      }
    }
  }
});
