import { defineStore } from "pinia";
import axios from "axios";
import { BACKEND_BASEURL } from "@/config/config";

export class EdaConnection {
  constructor(ecn, status) {
    this.ecn = ecn;
    this.status = status;
  }
}

/**
 * EDA connection store
 * Uniquely identifies EdaConnection by ECN
 */
export const useEdaConnectionStore = defineStore('edaConnection', {
  state: () => ({
    connections: [],
  }),
  actions: {
    async fetchEdaConnections() {
      try {
        const response = await axios.get(`${BACKEND_BASEURL}edaConnections/all`);
        this.connections = response.data;
      } catch (error) {
        console.error('Failed to fetch EDA connections:', error);
      }
    },

    async getEdaConnectionById(id) {
      try {
        const response = await axios.get(`${BACKEND_BASEURL}edaConnections/${id}`);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch EDA connection by ID:', error);
      }
    },

    async createEdaConnection(newConnection) {
      try {
        const response = await axios.post(`${BACKEND_BASEURL}edaConnections`, newConnection);
        this.connections.push(response.data);
      } catch (error) {
        console.error('Failed to create EDA connection:', error);
      }
    },

    async updateEdaConnection(id, updatedConnection) {
      try {
        const response = await axios.put(`${BACKEND_BASEURL}edaConnections/${id}`, updatedConnection);
        const index = this.connections.findIndex(connection => connection._id === id);
        if (index !== -1) {
          this.connections[index] = response.data;
        }
      } catch (error) {
        console.error('Failed to update EDA connection:', error);
      }
    },

    async deleteEdaConnection(id) {
      try {
        await axios.delete(`${BACKEND_BASEURL}edaConnections/${id}`);
        this.connections = this.connections.filter(connection => connection._id !== id);
      } catch (error) {
        console.error('Failed to delete EDA connection:', error);
      }
    }
  }
});
