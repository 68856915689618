import Keycloak from 'keycloak-js';

// https://www.keycloak.org/docs/latest/securing_apps/#javascript-adapter-reference

const initOptions = {
    url:        import.meta.env.VITE_KEYCLOAK_URL,
    realm:      import.meta.env.VITE_KEYCLOAK_REALM,
    clientId:   import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
};

const _keycloak = new Keycloak(initOptions);

export default {
    install: (app, options) => {
        // app.$keycloak = _keycloak;

        // @todo check for https://v3-migration.vuejs.org/breaking-changes/global-api.html#vue-prototype-replaced-by-config-globalproperties
        // Object.defineProperties(app.prototype), {
        Object.defineProperties(app, {
            $keycloak: {
                get() {
                    return _keycloak;
                },
            },
            logout() { /// @todo test
                app.$keycloak.logout();
            },
        });

        // Object.defineProperties(app.prototype, {
        //     logout() {
        //         app.$keycloak.logout();
        //     },
        // });
    }
};
