import { createI18n } from 'vue-i18n';
import en from '../i18n/en.json';
import de from '../i18n/de.json';
import it from '../i18n/it.json';
import { en as enVuetify, de as deVuetify, it as itVuetify } from 'vuetify/locale'

const i18n = createI18n({
  legacy: false,
  locale: 'de', // default locale
  fallbackLocale: 'en', // fallback locale
  messages: {
    en: {
      ...en,
      $vuetify: {
        ...enVuetify
      }
    },
    de: {
      ...de,
      $vuetify: {
        ...deVuetify
      }
    },
    it: {
      ...it,
      $vuetify: {
        ...itVuetify
      }
    }
  }
});

export default i18n;
