/**
 * plugins/vuetifyConf.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import "vuetify/styles";
import {  soStromThemeConfig } from '@es-bill/es-bill-common-components'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import {useI18n} from "vue-i18n";
import i18n from "@/plugins/i18n";


export function configureVuetify (app) {

  /// VUETIFY
  const vuetify = createVuetify({

    locale: {
      adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    ...soStromThemeConfig,
  })

  app.use(vuetify);
}
