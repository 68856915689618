<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue';
import {useStoreMetaData} from "@/stores/storeMetaData";
import {DsoContract, useDsoContractStore} from "@/stores/dsoContractStore";
import router from "@/router/routerSetup";
import {useRoute} from "vue-router";
import {useDsoInformationStore} from "@/stores/dsoCompanyInformationStore";

const metaDataStore = useStoreMetaData();
const dsoContractStore = useDsoContractStore();
const dsoInformationStore = useDsoInformationStore();
const route = useRoute();
const $log = inject('log');


const valid = ref(false);
const form = ref(null);
const community = ref(null);
const gridOperator = ref(null);

const availableCommunities = computed(() => metaDataStore.allCommunities.map(c => ({...c, props: {subtitle: c.ecn + ' / ' + c.ecid}})));
const operatorsAvailable = computed(() => dsoInformationStore.informations.map(c => ({...c, props: {subtitle: c.ecn }})));

watch(() => route.params.id, loadExistingContract);
onMounted(() => loadExistingContract(route.params.id));

const existingContract = ref(undefined);

function loadExistingContract(id) {
  if (id) {
    dsoContractStore.getContractById(id).then(contract => {
      console.log('contract', contract)
      existingContract.value = contract;
      community.value = metaDataStore.allCommunities.find(c => c.ecn === contract.ecn);
      gridOperator.value = dsoInformationStore.informations.find(i => i.ecn === contract.ecnDso);
    });
  }
}

const validateUrl = value => {
  const pattern = /^https:\/\/(?:[\w-]{2,}\.)+[\w-]{2,}(\/[\w-.,@?^=%&:\/~+#]*[\w-@?^=%&\/~+#])?$/;
  return pattern.test(value) || 'Enter a valid URL that starts with https://';
};
const submitForm = () => {
  if (form.value.validate()) {
    // Submit form data
    console.log('community', community.value.name);
    console.log('community', community.value.ecn);
    console.log('community', community.value.ecid);
    console.log('gridOperator', gridOperator.value.name);
    console.log('gridOperator', gridOperator.value.ecn);

    if (existingContract.value) {
      dsoContractStore.updateContract(existingContract.value._id, new DsoContract(
        community.value.name,
        community.value.ecn,
        community.value.ecid,
        gridOperator.value.name,
        gridOperator.value.ecn
      ));
    } else {
      dsoContractStore.createContract(new DsoContract(
        community.value.name,
        community.value.ecn,
        community.value.ecid,
        gridOperator.value.name,
        gridOperator.value.ecn
      ));
    }
  }

  // navigate to dsoContracts
  router.push({name: 'dsoContracts'});
}
</script>

<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <h1 class="mb-10">{{ $t('createDsoContracts') }}</h1>

      <v-autocomplete
        bg-color="white"
        :label="$t('selectCommunity')"
        v-model="community"
        :items="availableCommunities"
        :filter-keys="['title','props.subtitle']"
        variant="outlined"
        item-value="name"
        item-title="name"
        return-object
        clearable
      ></v-autocomplete>
<!-- TODO:subtext ecn + ecid + per ecid anzeigen nicht per ecn-->
      <v-autocomplete
        bg-color="white"
        :label="$t('selectGridOperator')"
        v-model="gridOperator"
        :items="operatorsAvailable"
        :filter-keys="['title','props.subtitle']"
        variant="outlined"
        item-value="name"
        item-title="name"
        return-object
        clearable
      ></v-autocomplete>

      <v-btn
        :disabled="!valid"
        @click="submitForm"
      >
        {{ $t('submit') }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<style scoped>
/* Optional styling */
</style>
