<script setup>
import {computed, inject, onMounted, watchEffect} from "vue";
import {useRoute} from "vue-router";
import {useDisplay} from 'vuetify'
import HeaderBar from "@/components/NavBar.vue";
import {useStoreMetaData} from '@/stores/storeMetaData';
import {useI18n} from "vue-i18n";
import { FooterBar, Snackbar } from "@es-bill/es-bill-common-components";
import {VERSION_IDENTIFIER} from "@/config/config";

const route = useRoute()
const storeMetaData = useStoreMetaData();
const { t } = useI18n();
const { width } = useDisplay()
const $log = inject("logger");

// track home page for displaying special header image
let showMainImageAfterHeader = computed(() =>  route.name === "NotFoundComponent" || route.name === "ViewHome")

watchEffect(() => {
  if (route?.meta?.titleKey && t && t('title')) {
    document.title = t('title') + " | " + t(route.meta.titleKey);
  } else {
    document.title = t('title') || "Admin";
  }
});

onMounted(async () => {
  storeMetaData.updateAllMetaData().catch(error => {
    $log.warn("could not load essential meta data, reloading in 10s")
    setTimeout(()=>{location.reload()},10*1000);
  });
})

// Footer Text
const versionIdentifier = VERSION_IDENTIFIER;
const copyrightText = 'so-strom';
const footerLinks = computed(() => [
  { text: t('website'), href: 'https://so-strom.at' },
  { text: t('contact'), href: 'https://www.so-strom.at/kontakt/' },
  { text: t('impressum'), href: 'https://www.so-strom.at/impressum/' },
]);
const causeOfAction = computed(() => t('causeOfAction'));

//////////////////////////////////////////////////////////////////////////////////////////
</script>


<template>

  <v-app ref="vApp" id="vApp" class="vApp">

    <!--  HEADER  -->
    <HeaderBar/>

    <!--  MAIN  -->
    <v-main class="vMainbody">

      <v-img v-if="showMainImageAfterHeader" class="imageStartBase"
             height="291"
             aspect-ratio="1/1"
             cover
             src="@/assets/sostrom/iStock-1151338370.jpg"
             alt="so-strom-intro">

        <!-- do not use v-img here -->
        <img v-if="showMainImageAfterHeader" class="imageStartTop"
             height="300"
             width="300"

             src="@/assets/sostrom/stern_gelb.svg"
             alt="so-strom-stern">

      </v-img>

      <RouterView class="routerView" />
      <v-container class="containerWithSnackbar">
              <Snackbar class="snackbar"/>
      </v-container>
    </v-main>

    <!--  FOOOTER  -->
      <FooterBar
        :versionIdentifier="versionIdentifier"
        :copyrightText="copyrightText"
        :footerLinks="footerLinks"
        :causeOfAction="causeOfAction"
      />
  </v-app>

</template>

<style lang="scss">

// @todo: move to sostrom.css
.textFormHeadline {
  //margin: 0 8px 0 8px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 16px 0 16px 0;
}
.vMainbody{
--v-layout-top: 80px !important;
--v-layout-bottom: 108px !important;
}

.v-text-field {
  margin: 0 16px 12px 0;
}

// hint/error message box of v-text-field
.v-messages__message {
  margin-bottom: 4px;
}

.v-stepper-header {
  margin: 2px !important;
}

.checkboxes {
  margin-bottom: 16px;
}

.firstCheckbox {
  margin-top: 16px;
}

.v-pagination__prev,
.v-pagination__next {
  //display: none !important;
}

.v-pagination__item button {

}

.v-stepper-item__avatar {
  //background-color: #999 !important;
  color: white !important;

  font-size: 12px !important;;
  font-style: normal !important;;
  font-weight: 400 !important;;
  line-height: 18px !important;; /* 150% */
}

.formRow .v-col .v-text-field {

}

body {
  margin: 0;
}

.v-breadcrumbs {
  padding-left: 0 !important;
}

.v-breadcrumbs-divider {
  padding: 0 !important;
}

.v-checkbox .v-label {
  opacity: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}


.imageStartTop {
  float: left;
  margin-left: 110px;
  margin-top: -130px;
}


.routerView {
  //background-color: white;
  margin-top: 48px;
  width: 100%;
}


/* top right bottom left*/
/* padding: 16px 0px 16px 0px;*/

.titleText {
  padding: 0 0 0 40px;
  text-align: left;
}

.textIntermediate {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 133.333% */
}

.vcenter {
  align-items: center;
}

.colRight {
  text-align: right;
}

.textDescriptor {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 8px 0 8px 0;
}


.textDescriptorCompact {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.textDescriptorTitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.textDescriptorTitleSub {
  font-size: 18px;
  line-height: 20px;
  //font-weight: 500;
}
.textDescriptorSmall {
  font-size: 14px;
  line-height: 20px;
  //font-weight: 500;
}

.mainViewContent {
  background-color: white;
  margin-top: 8px;
}

.textHeadline {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  padding: 0px 0 8px 0;
}

.containerStandard {
  padding: 16px 0 16px 0;
  max-width: 100% !important;
}

.divider {
  margin-top: 16px;
  color: rgb(var(--v-theme-dividerColor));
}

.containerBase {
  padding: 0 16px 0 16px;
}

.textFooter {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.textTabHeading {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}


.formInput8 {
  min-width: calc(8*40px);
}

.formInput7 {
  min-width: calc(7*40px);
}

.formInput6 {
  min-width: calc(6*40px);
}

.formInput5 {
  min-width: calc(5*40px);
}

.formInput4 {
  min-width: calc(5*40px);
}

.formInput3 {
  min-width: calc(3*40px);
}

.formInput2 {
  min-width: calc(2*40px);
}

.formInput1 {
  min-width: calc(1*40px);
}

.overlayDiv {
  width: 100%;
  height: 100%;
  align-items: center;
  vertical-align: center;
}

.afterBreadCrumbs {
  margin-top: 32px;
}

</style>

<style scoped>

.vApp {
}


@media screen and (max-width: 800px) {
  .titleText {
    display: none;
  }
}

@media screen and (min-width: 1321px) {
  .routerView {
    max-width: 1280px !important;
  }
}

@media screen and (max-width: 1320px) {
  .routerView {
    //margin-left: 20px;
    //margin-right: 20px;
    width: calc(100% - 40px) !important;
  }
}

</style>
