<script setup>
import { computed, ref } from "vue";
import { useEdaConnectionStore } from "@/stores/edaConnectionStore";
import { useI18n } from 'vue-i18n';
import { useFrontEndStore } from "@/stores/frontEndStore";
import router from "@/router/routerSetup";
import {useStoreMetaData} from "@/stores/storeMetaData";
import { DialogConfirm } from '@es-bill/es-bill-common-components';

const { t } = useI18n();
const edaConnectionStore = useEdaConnectionStore();
const frontEndStore = useFrontEndStore();
const metaDataStore = useStoreMetaData();

const sortBy = [{ key: 'ecn', order: 'asc' }];

const tableHeader = computed(() => [
  { title: 'ECN', value: 'ecn' },
  { title: t('name'), value: 'name' },
  { title: t('status'), value: 'status' },
  { title: t('actions'), value: 'actions', sortable: false },
]);

edaConnectionStore.fetchEdaConnections();

const communitiesAvailable = computed(() => metaDataStore.allCommunities.map(c => ({...c, props: {subtitle: c.ecn + ' / ' + c.ecid}})));


const edaConnections = computed(() => {
  let connections = edaConnectionStore.connections;

  // Apply EDA filter
  if (frontEndStore.edaFilter.ecnList.length)
    connections = connections.filter(connection => frontEndStore.edaFilter.ecnList.includes(connection.ecn));

  // add name to each connection
  connections = connections.map(connection => {
    const community = metaDataStore.allCommunities.find(c => c.ecn === connection.ecn);
    connection.name = community ? community.name : 'Unknown Community';
    return connection;
  });

  return connections;
});
const isDialogVisible = ref(false);
const connectionId = ref(null); // To store the selected EDA Connection ID for deletion

function showDeleteDialog(id) {
  console.log(id)
  connectionId.value = id;
  isDialogVisible.value = true;
}

function handleConfirm() {
  if (connectionId.value) {
    edaConnectionStore.deleteEdaConnection(connectionId.value);
    connectionId.value = null; // Reset the EDA Connection ID
  }
  isDialogVisible.value = false;
}

function handleCancel() {
  connectionId.value = null; // Reset the selected ECN
  isDialogVisible.value = false;
}
</script>

<template>
  <v-container class="pa-0">
    <p class="textHeadline">{{ $t('edaConnections') }}</p>

    <v-row class="filterRow" no-gutters>
      <v-col cols="12">
        <v-autocomplete
          bg-color="white"
          :label="$t('community')"
          v-model="frontEndStore.edaFilter.ecnList"
          :items="communitiesAvailable"
          :filter-keys="['title','props.subtitle']"
          multiple
          variant="outlined"
          item-value="ecn"
          item-title="ecn"
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-container class="mainViewContent">
      <v-data-table
        :sort-by="sortBy"
        :headers="tableHeader"
        :items="edaConnections">

        <!-- Custom slots for status column -->
        <template v-slot:item.status="{ item }">
          <span>{{ t(`${item.status.toLowerCase()}`) }}</span>
        </template>

        <!-- Actions column -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="showDeleteDialog(item._id)"
            class="mr-2"
          >mdi-delete
          </v-icon>
          <v-icon
            @click="
             router.push({
               name: 'edaConnectionForm',
               params: { id: item._id }
             })"
          >mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
    <DialogConfirm
      :modelValue="isDialogVisible"
      @update:modelValue="isDialogVisible = $event"
      @confirm="handleCancel"
      @cancel="handleConfirm"
      :title="t('areYouSure')"
      :message="t('deleteConfirmation')"
      :confirmText="t('cancel')"
      :cancelText="t('confirm')"
    />
  </v-container>
</template>

<style scoped>
</style>

