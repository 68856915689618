<script setup>
import {inject, ref} from 'vue';
import axios from 'axios';
import {BACKEND_BASEURL} from "@/config/config";
import {useDsoInformationStore} from "@/stores/dsoCompanyInformationStore"; // ensure axios is installed using npm or yarn

const jsonData = ref([]);
const errorMessage = ref("");
const numberOfUpload = ref(undefined);

const dsoInformationStore = useDsoInformationStore();
const $log = inject('log');

function validateData(dso) {
  const ecnPattern = /^AT\d{6}$/;
  const zipPattern = /^\d{4}$/;

  if (!dso.name || !dso.nameEca || !ecnPattern.test(dso.ecn) ||
    !zipPattern.test(dso.zip) || (dso.linkCheckNetTopology && !dso.linkCheckNetTopology.startsWith('https://')) ||
    (dso.linkCustomerPortal && !dso.linkCustomerPortal.startsWith('https://'))) {
    return false;
  }
  return true;
}

function handleFileUpload(event) {
  numberOfUpload.value = undefined;
  const file = event.target.files[0];
  if (file && file.type === "application/json") {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        if (Array.isArray(data) && data.every(validateData)) {
          jsonData.value = data;
          errorMessage.value = "";
        } else {
          errorMessage.value = "Invalid file content";
          jsonData.value = [];
          $log.error("Invalid file content");
        }
      } catch (error) {
        errorMessage.value = "Error parsing JSON file: " + error.message;
        $log.error("Error parsing JSON file: " + error.message);
      }
    };
    reader.readAsText(file);
  } else {
    errorMessage.value = "Please upload a valid JSON file";
    $log.error("Invalid file type: " + file.type);
  }
}

function submitData() {
  dsoInformationStore.replaceAllDsoInformation(jsonData.value)
    .then((value) => {
      if (value) {
        errorMessage.value = "";
        jsonData.value = [];
        numberOfUpload.value = value;
      } else {
        numberOfUpload.value = undefined;
        errorMessage.value = "Error uploading data";
      }

    })
}
</script>


<template>
  <v-container>
    <h1 class=mb-15>{{ $t('uploadDsoJson') }}</h1>

      <v-alert type="warning" prominent>
        {{ $t('knowWhatYouAreDoing') }}
      </v-alert>

    <v-file-input
      class="mt-5"
      :label="$t('uploadJsonFile')"
      @change="handleFileUpload"
      accept=".json"
      variant="solo-filled"
    ></v-file-input>

    <v-btn
      :disabled="!jsonData.length"
      @click="submitData"
      color="primary"
    >
      {{ $t('submitData') }}
    </v-btn>


    <v-alert
      class="mt-5"
      v-if="errorMessage"
      type="error"
      dense
    >
      {{ errorMessage }}
    </v-alert>
    <v-alert
      class="mt-5"
      v-if="numberOfUpload !== undefined"
      type="success"
      dense
    >
      {{ numberOfUpload }} {{ $t('dsoUploadSuccessful') }}
    </v-alert>

  </v-container>
</template>


<style scoped>
</style>
