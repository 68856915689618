<script setup>

import {ref, watch} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const isActive = ref(false);
const title = ref("");
const message = ref("");
const confirmed = ref(false);

async function showDialog(titleArg, messageArg) {
  title.value = titleArg;
  message.value = messageArg;
  isActive.value = true;

  let watcherConfirm;
  let watcherActive;
  return new Promise((resolve) => {
    // either confirmConfirm or confirmAbort will be toggled...
    watcherConfirm = watch(() => confirmed.value, resolve)
    watcherActive = watch(() => isActive.value, resolve)
  }).then(() => {
    // NUTS
  }).finally(() => {
    isActive.value = false;
    watcherConfirm(); // "unwatch"
    watcherActive(); // "unwatch"
  });
}

defineExpose({ showDialog });

</script>

<template>
  <v-dialog
    v-model="isActive"
    width="auto"
  >
    <v-card
      max-width="400"
      :title="title"
    >
      <v-card-text v-html="message"></v-card-text>
      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          @click="confirmed = !confirmed"
        >{{ t('ok') }}</v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
