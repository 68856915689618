import {v4 as uuid} from 'uuid';

export const index = {

  // real ECN?
  validateEcnCommunitiesOrServiceProvider: function (ecn) {
    return this.isEcnValidExternal(ecn);
  },
  isEcnValidExternal: function (ecn) {
    return (/^(?:RC|GC|CC|EP)\d{6}$/).test(ecn);
  },
  isEcnValid: function (ecn) {
    return (/^(?:RC|GC|CC|RD|GD|CD|EP)\d{6}$/).test(ecn);
  },
  isEcnDso: function (ecn) {
    return (/^AT\d{6}$/).test(ecn);
  },
  isEcnValidInternalDemo: function (ecn) {
    return (/^(?:RD|GD|CD)\d{6}$/).test(ecn);
  },


  /**
   *
   * @param ecid
   * @returns {string}
   * @note use isEcidValid, isEcidValidExternal beforehand
   */
  getEcnCommunityFromEcid: function (ecid) {
    return ecid.substring(2 + 11, 2 + 11 + 8);
  },

  /**
   *
   * @param ecid
   * @returns {string}
   * @note use isEcidValid, isEcidValidExternal beforehand
   * @warn only use for GC and RC, otherwise it does not make sense or returns wrong information
   */
  getEcnDsoFromEcid: function (ecid) {
    return ecid.substring(0, 8);
  },

  /**
   * Tests the given string if compliant with ECID format, including internal demo communities and EP
   * @param ecid
   * @returns {boolean}
   */
  isEcidValid: function (ecid) {
    return (/^AT[C0-9]{2}\d{4}[A-Z0-9]{5}(?:RC|GC|CC|RD|GD|CD)\d{6}\d{12}$/).test(ecid);
    // was before BEG: AT\d{11}(?:RC|GC|CC|RD|GD|CD|EP)\d{6}\d{12}
  },

  /**
   * Tests the given string if compliant with ECID format, excluding internal demo communities and EP
   * @param ecid
   * @returns {boolean}
   */
  isEcidValidExternal: function (ecid) {
    return (/^AT\d{11}(?:RC|GC|CC)\d{6}\d{12}$/).test(ecid);
  },

  isMeteringPointValid: function (meteringPoint) {
    return (/^AT[a-zA-Z0-9]{31}$/).test(meteringPoint);
  },


  generateConversationId: function (ecn) {
    // to be freely generated basically
    // Vorschlag:
    // <Sender-ID><Systemdatum><Uhrzeit mit Millisekunden><lfd. Nummer>
    // z.B.
    // AT99999920191224134559123000123456

    // we use
    // <Sender-ID><Systemdatum><uuid>
    // 6          10           14    -> 30


    if (!this.validateEcnCommunitiesOrServiceProvider(ecn)) {
      throw Error("ecn not valid");
    }

    const now = new Date();
    const processDateString = now.toISOString().substr(0, 10).replace(/-/gi, '');
    const subjectUuid = uuid().replace(/-/gi, "").substring(14).toUpperCase();

    return `${ecn}${processDateString}${subjectUuid}`
  },

  generateMessageId: function (ecn) {
    return this.generateConversationId(ecn);
  },

  shortenIdentifiersLong(identifier) {
    if (!identifier)
      return "";
    if (identifier.length < 17)
      return identifier;

    return identifier.substring(0, 8) + "..." + identifier.substring(identifier.length - 9)
  }

};

export default index;
