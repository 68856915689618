<script setup>
import {computed, inject, ref} from "vue";
import {useDsoInformationStore} from "@/stores/dsoCompanyInformationStore"; // Make sure to create this store
import {useI18n} from 'vue-i18n';
import {useFrontEndStore} from "@/stores/frontEndStore";
import router from "@/router/routerSetup";
import { DialogConfirm } from '@es-bill/es-bill-common-components';

const $log = inject('log');
const {t} = useI18n();
const dsoInformationStore = useDsoInformationStore();
const frontEndStore = useFrontEndStore();

const sortBy = [{key: 'name', order: 'asc'}];

const tableHeader = computed(() => [
  {title: t('name'), value: 'name'},
  {title: 'ECN', value: 'ecn'},
  {title: t('zip'), value: 'zips'},
  {title: t('topology'), value: 'linkCheckNetTopology'},
  {title: t('portal'), value: 'linkCustomerPortal'},
  {title: t('actions'), value: 'actions', sortable: false},
]);

dsoInformationStore.fetchDsoInformation();

const operatorsAvailable = computed(() => dsoInformationStore.informations.map(c => ({...c, props: {subtitle: c.ecn }})));

const operatorList = computed(() => {
  let infos = dsoInformationStore.informations;

  // Apply dso filter
  if (frontEndStore.dsoFilter.ecnList.length)
    infos = infos.filter(info => frontEndStore.dsoFilter.ecnList.includes(info.ecn));

  return infos;
});

function openLink(url) {
  if (url) {
    $log.info('Opening link', url);
    window.open(url, '_blank');
  }
}

const isDialogVisible = ref(false);
const selectedEcn = ref(null); // To store the selected ECN for deletion

function showDeleteDialog(ecn) {
  selectedEcn.value = ecn;
  isDialogVisible.value = true;
}

function handleConfirm() {
  if (selectedEcn.value) {
    dsoInformationStore.deleteDsoInformation(selectedEcn.value);
    selectedEcn.value = null; // Reset the selected ECN
  }
  isDialogVisible.value = false;
}

function handleCancel() {
  selectedEcn.value = null; // Reset the selected ECN
  isDialogVisible.value = false;
}

</script>

<template>
  <v-container class="pa-0">
    <p class="textHeadline">{{ $t('dsoCompanies') }}</p>

    <v-row class="filterRow" no-gutters>
      <v-col cols="12">
        <v-autocomplete
          bg-color="white"
          :label="$t('filter') + ' ' + $t('dsos')"
          v-model="frontEndStore.dsoFilter.ecnList"
          :items="operatorsAvailable"
          :filter-keys="['title','props.subtitle']"
          multiple
          variant="outlined"
          item-value="ecn"
          item-title="name"
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-container class="mainViewContent">
      <v-data-table
        :sort-by="sortBy"
        :headers="tableHeader"
        :items="operatorList">

        <!-- Custom slots for link columns -->
        <template v-slot:item.zips="{ item }">
          <span>{{ item.zips.join(', ') }}</span>
        </template>

        <template v-slot:item.linkCheckNetTopology="{ item }">
          <v-icon
            :disabled="!item.linkCheckNetTopology"
            @click="openLink(item.linkCheckNetTopology, '_blank')">
            mdi-open-in-new
          </v-icon>
        </template>

        <template v-slot:item.linkCustomerPortal="{ item }">
          <v-icon
            :disabled="!item.linkCustomerPortal"
            @click="openLink(item.linkCustomerPortal)">
            mdi-open-in-new
          </v-icon>
        </template>

        <!-- Actions column -->
        <template v-slot:item.actions="{ item }">
          <v-icon
             @click="showDeleteDialog(item.ecn)"
            class="mr-2"
          >mdi-delete
          </v-icon>
          <v-icon
            @click="router.push({ name: 'dsoCompanyForm', params: { id: item.ecn }})"
          >mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-container>

     <!-- Include the DialogConfirm common component -->
    <DialogConfirm
      :modelValue="isDialogVisible"
      @update:modelValue="isDialogVisible = $event"
      @confirm="handleCancel"
      @cancel="handleConfirm"
      :title="t('areYouSure')"
      :message="t('deleteConfirmation')"
      :confirmText="t('cancel')"
      :cancelText="t('confirm')"
    />
  </v-container>

</template>

<style scoped>
</style>
