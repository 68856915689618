export function getResponseInfoCustomerProcesses(responseCode) {
  const e = responseCodesCustomerServices.find(e => e.responseCode === responseCode);
  if(e)
    return e;

  return   {
    "responseCode": 0,
    "description": "",
    "category": ""
  }
}


const responseCodesCustomerServices = [
  {
    "responseCode": 56,
    "description": "Zählpunkt nicht gefunden",
    "category": "Customer Processes"
  },
  {
    "responseCode": 188,
    "description": "Teilnahmefaktor von 100 % würde überschritten werden",
    "category": "Customer Processes"
  },
  {
    "responseCode": 185,
    "description": "Zählpunkt befindet sich nicht im Bereich der Energiegemeinschaft",
    "category": "Customer Processes"
  },
  {
    "responseCode": 184,
    "description": "Kunde hat optiert",
    "category": "Customer Processes"
  },
  {
    "responseCode": 177,
    "description": "Keine Datenfreigabe vorhanden",
    "category": "Customer Processes"
  },
  {
    "responseCode": 160,
    "description": "Verteilmodell entspricht nicht der Vereinbarung",
    "category": "Customer Processes"
  },
  {
    "responseCode": 159,
    "description": "Zu Prozessdatum ZP inaktiv bzw. noch kein Gerät eingebaut",
    "category": "Customer Processes"
  },
  {
    "responseCode": 158,
    "description": "ZP ist nicht teilnahmeberechtigt",
    "category": "Customer Processes"
  },
  {
    "responseCode": 156,
    "description": "ZP bereits zugeordnet",
    "category": "Customer Processes"
  },
  {
    "responseCode": 86,
    "description": "konkurrierende Prozesse",
    "category": "Customer Processes"
  },
  {
    "responseCode": 181,
    "description": "Gemeinschafts-ID nicht vorhanden",
    "category": "Customer Processes"
  },
  {
    "responseCode": 178,
    "description": "Consent existiert bereits",
    "category": "Customer Processes"
  },
  {
    "responseCode": 174,
    "description": "Angefragte Daten nicht lieferbar",
    "category": "Customer Processes"
  },
  {
    "responseCode": 173,
    "description": "Kunde hat auf Datenfreigabe nicht reagiert (Timeout)",
    "category": "Customer Processes"
  },
  {
    "responseCode": 172,
    "description": "Kunde hat Datenfreigabe abgelehnt",
    "category": "Customer Processes"
  },
  {
    "responseCode": 76,
    "description": "Ungültige Anforderungsdaten",
    "category": "Customer Processes"
  },
  {
    "responseCode": 57,
    "description": "Zählpunkt nicht versorgt",
    "category": "Customer Processes"
  },
  {
    "responseCode": 196,
    "description": "Teilnahme-Limit wird überschritten",
    "category": "Customer Processes"
  },
  {
    "responseCode": 82,
    "description": "Prozessdatum falsch",
    "category": "Customer Processes"
  },
  {
    "responseCode": 104,
    "description": "Falsche Energierichtung",
    "category": "Customer Processes"
  },
  {
    "responseCode": 99,
    "description": "Meldung erhalten",
    "category": "Customer Processes"
  },
  {
    "responseCode": 182,
    "description": "Noch kein fernauslesbarer Zähler eingebaut",
    "category": "Customer Processes"
  },
  {
    "responseCode": 183,
    "description": "Summe der gemeldeten Aufteilungsschlüssel übersteigt 100%",
    "category": "Customer Processes"
  },
  {
    "responseCode": 175,
    "description": "Zustimmung erteilt",
    "category": "Customer Processes"
  },
  {
    "responseCode": 0
  }
];
