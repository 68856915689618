// dsoCompanyInformationStore.js
import { defineStore } from "pinia";
import axios from "axios";
import { BACKEND_BASEURL } from "@/config/config";

export class DsoInformation {
  constructor(name, ecn, zip, linkCheckNetTopology, linkCustomerPortal) {
    this.name = name;
    this.ecn = ecn;
    this.zip = zip;
    this.linkCheckNetTopology = linkCheckNetTopology;
    this.linkCustomerPortal = linkCustomerPortal;
  }
}

/**
 * DSO information store
 * Uniquely identifies Dso by ECN
 */
export const useDsoInformationStore = defineStore('dsoInformation', {
  state: () => ({
    informations: [],
  }),
  actions: {
    async fetchDsoInformation() {
      try {
        const response = await axios.get(`${BACKEND_BASEURL}dsoInformation`);
        this.informations = response.data;
      } catch (error) {
        console.error('Failed to fetch DSO information:', error);
      }
    },

    async replaceAllDsoInformation(dsoData) {
      try {
        const response = await axios.post(`${BACKEND_BASEURL}dsoInformation/replace`, dsoData)
        return parseInt(response.data, 10);
      } catch (error) {
        console.error('Failed to replace DSO information:', error);
        return 0;
      }
    },

    async getDsoInformationByEcn(ecn) {
      try {
        const response = await axios.get(`${BACKEND_BASEURL}dsoInformation/${ecn}`);
        return response.data;
      } catch (error) {
        console.error('Failed to fetch DSO information by ECN:', error);
      }
    },

    async createDsoInformation(newInfo) {
      try {
        const response = await axios.post(`${BACKEND_BASEURL}dsoInformation`, newInfo);
        this.informations.push(response.data);
      } catch (error) {
        console.error('Failed to create DSO information:', error);
      }
    },

    async updateDsoInformation(ecn, updatedInfo) {
      try {
        const response = await axios.put(`${BACKEND_BASEURL}dsoInformation/${ecn}`, updatedInfo);
        const index = this.informations.findIndex(info => info.ecn === ecn);
        if (index !== -1) {
          this.informations[index] = response.data;
        }
      } catch (error) {
        console.error('Failed to update DSO information:', error);
      }
    },

    async deleteDsoInformation(ecn) {
      try {
        await axios.delete(`${BACKEND_BASEURL}dsoInformation/${ecn}`);
        this.informations = this.informations.filter(info => info.ecn !== ecn);
      } catch (error) {
        console.error('Failed to delete DSO information:', error);
      }
    }
  }
});
