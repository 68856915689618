<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useEdaConnectionStore } from "@/stores/edaConnectionStore";
import {useI18n} from "vue-i18n";
import {useStoreMetaData} from "@/stores/storeMetaData";

const router = useRouter();
const route = useRoute();
const {t} = useI18n();
const edaConnectionStore = useEdaConnectionStore();
const metaDataStore = useStoreMetaData();


const valid = ref(false);
const ecn = ref('');
const status = ref('');

const statusOptions = ['AVAILABLE', 'NOT_AVAILABLE'];
const communitiesAvailable = computed(() => metaDataStore.allCommunities.map(c => ({...c, props: {subtitle: c.ecn + ' / ' + c.ecid}})));
const isEditMode = computed(() => !!route.params.id);

const validateEcn = value => {
  const pattern = /^(?:RC|GC|CC|RD|GD|CD)\d{6}$/;
  return pattern.test(value) || t('enterValidEcnEda');
};

const validateRequired = value => !!value || t('fieldRequired');

const loadEdaConnection = (id) => {
  edaConnectionStore.getEdaConnectionById(id).then(connection => {
    ecn.value = connection.ecn;
    status.value = connection.status;
  });
};

const submitForm = () => {
  if (valid.value) {
    const edaConnection = {
      ecn: ecn.value,
      status: status.value,
    };

    if (isEditMode.value) {
      edaConnectionStore.updateEdaConnection(route.params.id, edaConnection);
    } else {
      edaConnectionStore.createEdaConnection(edaConnection);
    }

    router.push({ name: 'edaConnections' });
  }
};

onMounted(() => {
  if (isEditMode.value) {
    loadEdaConnection(route.params.id);
  }
});
</script>

<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <h1 class="mb-10">{{ isEditMode ? $t('editEdaConnection') : $t('createEdaConnection') }}</h1>

      <v-autocomplete
        bg-color="white"
        :label="$t('ecn')"
        v-model="ecn"
        :items="communitiesAvailable"
        variant="outlined"
        :rules="[validateEcn]"
        :filter-keys="['title','props.subtitle']"
        item-value="ecn"
        item-title="name"
        required
        clearable
      ></v-autocomplete>

      <v-select
        bg-color="white"
        :label="$t('status')"
        v-model="status"
        variant="outlined"
        :rules="[validateRequired]"
        :items="statusOptions"
        required
        clearable
      ></v-select>

      <v-btn :disabled="!valid" @click="submitForm">
        {{ $t('submit') }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<style scoped>
</style>
