// we inject app here since this. only work in components
export default async function (app) {

    try {
        app.$log.debug("updateToken start");
        const refreshed = await app.$keycloak.updateToken(70);
        if (refreshed) {
            app.$log.debug('Token refreshed');
        } else {
            app.$log.debug('Token not refreshed, valid for '
              + Math.round(app.$keycloak.tokenParsed.exp + app.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }

        // throw regardless to of above result just to test (e.g. on window onFocues)
        // throw new Error('just a test')
    } catch (err) {
        app.$log.error('Failed to refresh token');
        /// try reloading current page, should re-init everything... I hope
        location.reload();
    }

    return app.$keycloak.token;
}