import {defineStore} from 'pinia'
import {computed, inject, ref} from "vue";

// track front end settings
export const useFrontEndStore
  = defineStore('frontendStore', () => {

    // contract view
    const communityFilter = ref({
      ecnList: []
    });

    const operatorFilter = ref({
      ecnList: []
    });

    // dso view
    const dsoFilter = ref({
      ecnList: []
    });

    // eda view
    const edaFilter = ref({
      ecnList: []
    });

    const communityCompanyFilter = ref({
      ecnList: []
    });

    const pageSettingsFilter = ref({
      viewApplicants: {
        status: [],
        search: "",
        page: 1,
        itemsPerPage: 10,
      },
      viewMeteringPoints: {
        status: [],
        search: "",
        page: 1,
        itemsPerPage: 10,
      },
      viewMembers: {
        status: [], // keep all props the same
        search: "",
        page: 1,
        itemsPerPage: 10,
      },
      viewCommunities: {
        status: [], // keep all props the same
        search: "",
        page: 1,
        itemsPerPage: 10,
      },
    });


    return {
      edaFilter,
      dsoFilter,
      communityFilter,
      operatorFilter,
      communityCompanyFilter,
      pageSettingsFilter,
    }
  },
  {
    persist: {
      storage: sessionStorage,
      // paths: ['_communityCompany',],
      key: "frontendsettings",
    },
  }
  );
