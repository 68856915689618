<script setup>

import {computed, watchEffect, ref} from "vue";
import {useDsoContractStore} from "@/stores/dsoContractStore";
import { useI18n } from 'vue-i18n';
import {useFrontEndStore} from "@/stores/frontEndStore";
import {useStoreMetaData} from "@/stores/storeMetaData";
import router from "@/router/routerSetup";
import {useDsoInformationStore} from "@/stores/dsoCompanyInformationStore";
import { DialogConfirm } from '@es-bill/es-bill-common-components';



const { t } = useI18n();
const dsoContractStore = useDsoContractStore();
const dsoInformationStore = useDsoInformationStore();
const frontEndStore = useFrontEndStore();
const metaDataStore = useStoreMetaData();


const sortBy = [{ key: 'name', order: 'asc' }];

const tableHeader = computed(() => [
  { title: t('name') + ' ' + t('community'), value: 'name' },
  { title: 'ECN ' + t('community'), value: 'ecn' },
  { title: 'ECID ' + t('community'), value: 'ecid' },
  { title: t('name') + ' ' + t('grid_operator'), value: 'nameDso' },
  { title: 'ECN ' + t('grid_operator'), value: 'ecnDso' },
  { title: t('actions'), value: 'actions', sortable: false },
]);

const communitiesAvailable = computed(() => metaDataStore.allCommunities.map(c => ({...c, props: {subtitle: c.ecn + ' / ' + c.ecid}})));
const operatorsAvailable = computed(() => dsoInformationStore.informations.map(c => ({...c, props: {subtitle: c.ecn }})));

watchEffect(() => {
  dsoContractStore.fetchContracts();
  dsoInformationStore.fetchDsoInformation();
});

const dso_contracts = computed(() => {
  let contracts = dsoContractStore.contracts.map(contract => {
    const community = metaDataStore.allCommunities.find(c => c.ecn === contract.ecn);
    const dso = dsoInformationStore.informations.find(i => i.ecn === contract.ecnDso);

    return {
      ...contract,
      name: community ? community.name : 'Unknown Community',
      nameDso: dso ? dso.name : 'Unknown DSO',
    };
  });

  // filter by community
  if(frontEndStore.communityFilter.ecnList.length)
    contracts = contracts.filter(c => frontEndStore.communityFilter.ecnList.includes(c.ecn));
  //filter by operator
  if(frontEndStore.operatorFilter.ecnList.length)
    contracts = contracts.filter(c => frontEndStore.operatorFilter.ecnList.includes(c.ecnDso));

  return contracts;
});
const isDialogVisible = ref(false);
const contractId = ref(null); // To store the selected ECN for deletion

function showDeleteDialog(id) {
  console.log(id)
  contractId.value = id;
  isDialogVisible.value = true;
}

function handleConfirm() {
  if (contractId.value) {
    dsoContractStore.deleteContract(contractId.value)
    contractId.value = null; // Reset the selected ECN
  }
  isDialogVisible.value = false;
}

function handleCancel() {
  contractId.value = null; // Reset the selected ECN
  isDialogVisible.value = false;
}
</script>

<template>
  <v-container class="pa-0">
    <p class="textHeadline">{{ $t('dsoContracts') }}</p>

    <v-row class="filterRow" no-gutters>
      <v-col cols="6">
        <v-autocomplete
          bg-color="white"
          :label="$t('community')"
          v-model="frontEndStore.communityFilter.ecnList"
          :items="communitiesAvailable"
          :filter-keys="['title','props.subtitle']"
          multiple
          variant="outlined"
          item-value="ecn"
          item-title="name"
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          bg-color="white"
          :label="$t('grid_operator')"
          v-model="frontEndStore.operatorFilter.ecnList"
          :items="operatorsAvailable"
          :filter-keys="['title','props.subtitle']"
          multiple
          variant="outlined"
          item-value="ecn"
          item-title="name"
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-container class="mainViewContent">

      <v-data-table
        :sort-by="sortBy"
        :headers="tableHeader"
        :items="dso_contracts">

        <!-- Custom slot for the actions column -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="showDeleteDialog(item._id)"
            class="mr-2"
          >mdi-delete
          </v-icon>
          <v-icon
            @click="router.push({name: 'createDsoContracts', params: {id: item._id}})"
          >mdi-pencil
          </v-icon>
        </template>
      </v-data-table>

    </v-container>
    <!-- Include the DialogConfirm common component -->
    <DialogConfirm
      :modelValue="isDialogVisible"
      @update:modelValue="isDialogVisible = $event"
      @confirm="handleCancel"
      @cancel="handleConfirm"
      :title="t('areYouSure')"
      :message="t('deleteConfirmation')"
      :confirmText="t('cancel')"
      :cancelText="t('confirm')"
    />
  </v-container>
</template>

<style scoped>

</style>
