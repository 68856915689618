<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {useDsoInformationStore} from "@/stores/dsoCompanyInformationStore";
import {useI18n} from "vue-i18n";

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const $log = inject('log');
const dsoInformationStore = useDsoInformationStore();

const existingDsoCompanyInfo = ref(undefined);
const valid = ref(false);
const name = ref('');
const nameEca = ref('');
const ecn = ref('');
const zips = ref('');
const topology = ref('');
const portal = ref('');

const isNewCompany = computed(() => !existingDsoCompanyInfo.value);

watch(() => route.params.id, loadExistingCompany);
onMounted(() => loadExistingCompany(route.params.id));

function loadExistingCompany(enc) {
  if (enc) {
    dsoInformationStore.getDsoInformationByEcn(enc).then(company => {
      $log.debug('company', company);
      existingDsoCompanyInfo.value = company;
      name.value = company.name;
      nameEca.value = company.nameEca;
      ecn.value = company.ecn;
      zips.value = company.zips.join(', ');
      topology.value = company.linkCheckNetTopology;
      portal.value = company.linkCustomerPortal;
    });
  }
}

const validateUrl = value => {
  const pattern = /^https:\/\/(?:[\w-]{2,}\.)+[\w-]{2,}(\/[\w-.,@?^=%&:\/~+#]*[\w-@?^=%&\/~+#])?$/;
  return pattern.test(value) || t('enterValidUrl');
};

const validateEcn = value => {
  const pattern = /^AT\d{6}$/;
  return pattern.test(value) || t('enterValidEcn');
};

const validateRequired = value => !!value || t('fieldRequired');

const validateZips = value => {
  const pattern = /^\d{4}(,\d{4})*$/;
  return pattern.test(value) || t('enterValidZips');
};

const submitForm = () => {
  if (valid.value) {
    $log.debug('Submitted Topology:', topology.value);
    $log.debug('Submitted Portal:', portal.value);

    if (existingDsoCompanyInfo.value) {
      dsoInformationStore.updateDsoInformation(existingDsoCompanyInfo.value.ecn, {
        linkCheckNetTopology: topology.value,
        linkCustomerPortal: portal.value
      });
    } else {
    // TODO: log
      $log.debug('Submitted ECN:', ecn.value);
      $log.debug('Submitted Name:', name.value);
      $log.debug('Submitted Name ECA:', nameEca.value);
      $log.debug('Submitted Zips:', zips.value);
      dsoInformationStore.createDsoInformation({
        ecn: ecn.value,
        name: name.value,
        nameEca: nameEca.value,
        zips: zips.value.split(',').map(zip => zip.trim()),
        linkCheckNetTopology: topology.value,
        linkCustomerPortal: portal.value
      });
    }

    router.push({ name: 'dsoCompanies' });
  }
};
</script>

<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <h1 class="mb-10">{{ $t('dsoCompanyForm') }}</h1>

        <v-text-field
          bg-color="white"
          :label="$t('name')"
          v-model="name"
          variant="outlined"
          :rules="[validateRequired]"
          required
          clearable
          :disabled="!isNewCompany"
        ></v-text-field>

        <v-text-field
          bg-color="white"
          :label="$t('nameEca')"
          v-model="nameEca"
          variant="outlined"
          :rules="[validateRequired]"
          required
          clearable
          :disabled="!isNewCompany"
        ></v-text-field>

        <v-text-field
          bg-color="white"
          :label="$t('ecn')"
          v-model="ecn"
          variant="outlined"
          :rules="[validateEcn]"
          required
          clearable
          :disabled="!isNewCompany"
        ></v-text-field>

        <v-text-field
          bg-color="white"
          :label="$t('zips')"
          v-model="zips"
          variant="outlined"
          :rules="[validateZips]"
          required
          clearable
          :hint="$t('enterZipsSeperatedCommas')"
          persistent-hint
          :disabled="!isNewCompany"
        ></v-text-field>

      <v-text-field
        bg-color="white"
        :label="$t('topology')"
        v-model="topology"
        variant="outlined"
        :rules="[validateUrl]"
        required
        clearable
      ></v-text-field>

      <v-text-field
        bg-color="white"
        :label="$t('portal')"
        v-model="portal"
        variant="outlined"
        :rules="[validateUrl]"
        required
        clearable
      ></v-text-field>

      <v-btn :disabled="!valid" @click="submitForm">
        {{ $t('submit') }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<style scoped>
</style>

