<script setup>
import {useI18n} from "vue-i18n";
import {computed, inject} from "vue";


import edaTools from "@/lib/es-bill-shared-js/misc/edaTools";

import { useStoreMetaData } from '@/stores/storeMetaData'

const metaDataStore = useStoreMetaData();
const { t } = useI18n();
const $log = inject('log');

const communitiesNoDsoNot16bOrEp = computed(() => {
  return metaDataStore.allCommunities.filter(c =>
    (!c.ecnDso || c.ecnDso.length===0) &&
    edaTools.isEcnValidExternal(c.ecn) &&
    c.communityType !== "16b" &&
    c.communityType !== "EP"
  )
})

const communitiesNotDemo = computed(() => {
  return metaDataStore.allCommunities.filter(c =>
    edaTools.isEcnValidExternal(c.ecn)
  )
})

const membershipsNotDemo = computed(() => {
  return metaDataStore.allMeteringPointMemberships.filter(mpm =>
    edaTools.isEcnValidExternal(mpm.ecn))
})

function getLineForMpStatus(mpmStatus) {
  const countByState = membershipsNotDemo.value.filter(mpm => mpm.membershipStatus === mpmStatus).length;
  const countAll = membershipsNotDemo.value.length;

  return countByState+" ("+(Math.round(countByState*100/countAll))+"%)";
}


</script>


<template>
  <v-container class="containerBase pa-0">

    <v-container class="containerStandard">

      <p class="textHeadline">{{ $t('dashboard') }}</p>

      <v-divider class="divider"/>

      <div>
        <h2>Stats</h2>
        <u>Communities: {{ communitiesNotDemo.length }}</u><br>
        GEAs: {{ communitiesNotDemo.filter(c => c.communityType === "16a").length }}<br>
        BEGs: {{ communitiesNotDemo.filter(c => c.communityType === "16b").length }}<br>
        EEGs: {{ communitiesNotDemo.filter(c => c.communityType === "16c").length }}<br>
        EPs:  {{ communitiesNotDemo.filter(c => c.communityType === "EP").length }}<br>
        <br>

        <u>MPMs (sum, non-Demo): {{ membershipsNotDemo.length }}</u><br>
        CREATED:          {{ getLineForMpStatus("CREATED") }}<br>
        STARTED:          {{ getLineForMpStatus("STARTED") }}<br>
        WAITING_FOR_USER: {{ getLineForMpStatus("WAITING_FOR_USER") }}<br>
        ACCEPTED:         {{ getLineForMpStatus("ACCEPTED") }}<br>
        ACTIVE:           {{ getLineForMpStatus("ACTIVE") }}<br>
        ENDING:           {{ getLineForMpStatus("ENDING") }}<br>
        ENDED:            {{ getLineForMpStatus("ENDED") }}<br>
        DECLINED:         {{ getLineForMpStatus("DECLINED") }}<br>
      </div>


      <div>
        <br>
        <b>Communities (technical), no BEGs and no ECN for DSO set:</b>
        <ul class="listGeneric">
          <li v-for="community in communitiesNoDsoNot16bOrEp">{{community.name}}</li>
        </ul>

      </div>


    </v-container>
  </v-container>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>

<style>

.listGeneric {
  margin-left: 20px;
}

</style>
