import axios from 'axios';
import {BACKEND_BASEURL} from "@/config/config";


let configBackendBaseUrl = "";
let $log;

// used by app.use
export default function setup(app) {
  // configBackendBaseUrl = app.$appconfig.BACKEND_BASEURL;
  configBackendBaseUrl = BACKEND_BASEURL;
  $log = app.$log;
}

  // GET ENERGY DATA
  /**
 * Select start/end date according to metering point status.
 *
 * Takes an array with a list of metering points and start/end dates.
 * Checks the status of each metering point.
 * Selects start/end date for fetching energy data according to
 * metering point membership dates.
 *
 * @param {array} requestEnergyData - Selected metering points and start/end dates from date picker.
 * @returns {array} - Metering point id, start date, end date for each valid metering point.
 */
function selectDateRangePerMp(requestEnergyData) {
  const fetchStartDate = new Date(requestEnergyData.dateStart);
  const fetchEndDate = new Date(requestEnergyData.dateEnd);
  const fetchData = [];

  // Loop through selected metering points
  for (const mp of requestEnergyData.meteringPointsData) {

    // Active metering points
    if (mp.membershipStatus === 'ACTIVE') {
      // Mp start date before fetch start date
      if (mp.dateStart <= fetchStartDate) {
        fetchData.push({
          ecn: mp.ecn,
          mpmObjectId: mp.mpmObjectId,
          dateStart: fetchStartDate,
          dateEnd: fetchEndDate
        });
      }
      // Mp start date after fetch end date
      else if (mp.dateStart >= fetchEndDate) {
        continue;
      }
      // Mp start date after fetch start date
      else {
        fetchData.push({
          ecn: mp.ecn,
          mpmObjectId: mp.mpmObjectId,
          dateStart: mp.dateStart,
          dateEnd: fetchEndDate
        });
      }
    }
    // Ended metering points
    else if (mp.membershipStatus === 'ENDED' || mp.membershipStatus === 'ENDING') {
      // Mp active period outside of fetch period
      if (mp.dateEnd <= fetchStartDate || mp.dateStart >= fetchEndDate) {
        continue;
      }
      // Mp active period starts before fetch period
      else if (mp.dateStart <= fetchStartDate) {
        // Mp end date before fetch end date
        if (mp.dateEnd < fetchEndDate) {
          fetchData.push({
            ecn: mp.ecn,
            mpmObjectId: mp.mpmObjectId,
            dateStart: fetchStartDate,
            dateEnd: mp.dateEnd
          });
        }
        // Mp end date after fetch end date
        else {
          fetchData.push({
            ecn: mp.ecn,
            mpmObjectId: mp.mpmObjectId,
            dateStart: fetchStartDate,
            dateEnd: fetchEndDate
          });
        }
      }
      // Mp active period starts during fetch period
      else if (mp.dateStart >= fetchStartDate) {
        // Mp end date before fetch end date
        if (mp.dateEnd < fetchEndDate) {
          fetchData.push({
            ecn: mp.ecn,
            mpmObjectId: mp.mpmObjectId,
            dateStart: mp.dateStart,
            dateEnd: mp.dateEnd
          });
        }
        // Mp end date after fetch end date
        else {
          fetchData.push({
            ecn: mp.ecn,
            mpmObjectId: mp.mpmObjectId,
            dateStart: mp.dateStart,
            dateEnd: fetchEndDate
          });
        }
      }
    }
  }
  return fetchData;
}

/**
 * Request energy data for selected metering point and date range from DSO
 *
 * @param {string} ecn - Energy community number.
 * @param {string} mpmObjectId - Metering point ID.
 * @param {date} dateStart - Start date for fetching energy data.
 * @param {date} dateEnd - End date for fetching energy data.
 * @returns {Promise}
 */
async function requestEnergyDataForMp(ecn, mpmObjectId, dateStart, dateEnd) {
  if (!ecn) {
    $log.error('No ECN provided. Cannot restart metering point onboarding.');
    throw new Error('No ECN provided');
  } else if (!mpmObjectId) {
    $log.error('No metering point ID provided. Cannot restart metering point onboarding.');
    throw new Error('No metering point ID provided');
  }
  try {
    const response = await axios
    .post(`${configBackendBaseUrl}meteringPoint/${ecn}/requestEnergyData/${mpmObjectId}`, {dateStart: dateStart, dateEnd: dateEnd});
    return response.data;
  } catch (error) {
    $log.error('Error fetching energy data:', error);
    throw error;
  }
}

/**
 * Start get energy data process.
 *
 * Use list with metering points and date range for each metering point
 * to get the energy data for each metering point.
 *
 * @param {array} requestEnergyData - Selected metering points and start/end dates from date picker.
 * @returns {Promise}
 */
export async function startEnergyDataRequest(requestEnergyData) {
  const mpList = selectDateRangePerMp(requestEnergyData);

  if(mpList.length === 0)
    return {
      requestsWanted: requestEnergyData.meteringPointsData.length,
      callsGenerated: 0,
      callsSucceeded: 0,
      callsFailed: 0,
    }

  let callsSucceeded = 0;
  let callsFailed = 0;



  try {

    for (const mp of mpList) {
      try {
        const response = await requestEnergyDataForMp(mp.ecn, mp.mpmObjectId, mp.dateStart, mp.dateEnd);
        callsSucceeded++;
        $log.debug("startGetEnergyData, called succeeded", response);
      } catch (e) {

        $log.warn("startGetEnergyData, called failed", e);
        callsFailed++;
      }
    }

    return {
      requestsWanted: requestEnergyData.meteringPointsData.length,
      callsGenerated: mpList.length,
      callsSucceeded: callsSucceeded,
      callsFailed: callsFailed,
    }

  }
  catch (error) {
    $log.error('Error fetching energy data:', error);
    throw error; // should now only be syntactical errors
  }
}
