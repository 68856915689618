<script setup>

import {computed, inject, ref} from "vue";
import {useI18n} from "vue-i18n";

const localeToUse = ref("de-AT")

import {useFrontEndStore} from "@/stores/frontEndStore";
import {useStoreMetaData} from "@/stores/storeMetaData";

const billingPeriodDateStart2023 = ref(new Date(2023,1-1,1));
const billingPeriodDateEnd2023   = ref(new Date(2023,12-1,31));

const billingPeriodDateStart2024 = ref(new Date(2024,1-1,1));
const billingPeriodDateEnd2024   = ref(new Date(2024,12-1,31));

const { t } = useI18n();
const $log = inject('log');

const frontEndStore = useFrontEndStore();
const metaDataStore = useStoreMetaData();

const communitiesReport = computed(() => {
  let cr = [];

  for(const c of  metaDataStore.allCommunities) {
    // $log.warn(c);

    const mpms = metaDataStore.allMeteringPointMemberships.filter(mpm => mpm.ecid === c.ecid);
    let mpmsCalced = [];
    let mpmMonth = 0;

    /// memberships 2023 post calc (Energieabhängiges Modell)
    for(const mpm of mpms) {

      // never reached active state
      if(mpm.membershipStatus === "CREATED")
        continue;
      if(mpm.membershipStatus === "STARTED")
        continue;
      if(mpm.membershipStatus === "DECLINED")
        continue;
      if(mpm.membershipStatus === "WAITING_FOR_USER")
        continue;
      if(mpm.membershipStatus === "ACCEPTED")
        continue;

      // ended before our time period
      if(mpm.dateEnd && mpm.dateEnd < billingPeriodDateStart2023.value)
        continue;
      if(mpm.dateStart > billingPeriodDateEnd2023.value)
        continue;

      let monthStart = 1;
      let monthEnd   = 12;
      if(mpm.dateStart > billingPeriodDateStart2023.value)
        monthStart = mpm.dateStart.getMonth() + 1;
      if(mpm.dateEnd && mpm.dateEnd < billingPeriodDateEnd2023.value)
        monthEnd = mpm.dateEnd.getMonth() + 1;

      const countMonth = monthEnd-monthStart + 1;
      mpmMonth+=countMonth;

      mpmsCalced.push({
        ...mpm,
        countMonth,
      })
    }

    /// membership calc 2024 (Pauschalmodell)
    let md = [];
    let dateCurrent = new Date(billingPeriodDateStart2024.value);

    while(dateCurrent <= billingPeriodDateEnd2024.value) {
      const dateStart = dateCurrent;
      let dateEnd = new Date(dateStart);
      dateEnd.setMonth(dateEnd.getMonth()+1)
      dateEnd.setDate(0);

      let mpsAccountedFor = [];
      let mpsMultiple = [];

      let countMpmActive = 0;
      let countMpmActivated = 0;
      let countMpmDeactivated = 0;
      let countMpmActiveDouble = 0;

      for(const mpm of mpms) {

        // never reached active state
        if (mpm.membershipStatus === "CREATED")
          continue;
        if (mpm.membershipStatus === "STARTED")
          continue;
        if (mpm.membershipStatus === "DECLINED")
          continue;
        if (mpm.membershipStatus === "WAITING_FOR_USER")
          continue;
        if (mpm.membershipStatus === "ACCEPTED")
          continue;

        // ended before our time period
        if (mpm.dateEnd && mpm.dateEnd < dateStart)
          continue;
        // started after
        if (mpm.dateStart > dateEnd)
          continue;

        // was active during month
        if(!mpsAccountedFor.includes(mpm.meteringPoint)) {
          countMpmActive++;

          mpsAccountedFor.push(mpm.meteringPoint)
        }
        else {
          $log.warn("MP was active more than once",dateStart.toLocaleDateString(localeToUse.value),mpm.meteringPoint,mpm.ecn);
          countMpmActiveDouble++;
          mpsMultiple.push(mpm.meteringPoint)
        }

        if (mpm.dateStart >= dateStart)
          countMpmActivated++;  // entry this month
        if (mpm.dateEnd &&  mpm.dateEnd <= dateEnd)
          countMpmDeactivated++; // exit this month
      }

      md.push({
        timespan: dateStart.toLocaleDateString(localeToUse.value)+" > "+dateEnd.toLocaleDateString(localeToUse.value),
        countMpmActive: countMpmActive,
        countMpmActivated: countMpmActivated,
        countMpmDeactivated: countMpmDeactivated,
        countMpmActiveDouble: countMpmActiveDouble,
        mpsMultiple: mpsMultiple,
      })
      dateCurrent.setMonth(dateCurrent.getMonth() + 1);
    }

    cr.push({
      ...c,
      mpmsAll: mpms,

      // for 2023 calc (Energieabhängiges Modell)
      mpms: mpmsCalced,
      mpmMonth,

      // for 2024 calc (Pauschalmodell)
      monthData: md,
    });
  }


  return cr;
});


</script>


<script>
export default {
  name: 'meteringPointsAnalysis',
}
</script>


<template>
  <v-container class="containerBase pa-0">

    <v-container class="containerStandard">

      <p class="textHeadline">{{ $t('meteringPoint') + " " + $t('analysis') }}</p>
      Analyse der Zählpunkte der Gemeinschaften über Zeit (für Abrechnung So-Strom/KundInnen).<br><br>
      <p>
        <u>Regeln:</u> Ein Monat für einen Zählpunkt wird verechnet, wenn dieser zumindest einen Tag lange aktiv war.
        EnergieUNabhängig: Pro Monat die aktiven und aktivierten Mitgliedschaften heranziehen + Basispauschale.<br>
        EnergieABhändig: Nachverrechnung Zählpunktmonate/-Jahre für 2023 (Differenz zur Rechnungslegung letztes Jahr)<br>
        sowie aktuell aktive Zählpunkte/Mitgliedschaften (Count MPMs ACTIVE) oder Eintrag vom Jänner (aktiv) - Mindestanzahl!
        Zusätzlich die Anmeldungen berücksichtigen.
      </p>

      <br>
      Start-Datum: {{ billingPeriodDateStart2023.toLocaleDateString(localeToUse) }}<br>
      End-Datum: {{ billingPeriodDateEnd2023.toLocaleDateString(localeToUse) }}<br>
      <br>

      <div v-for="community of communitiesReport">
        <b>Name: {{community.name}} <br></b>
        ECN: {{community.ecn}} <br>
        ECID: {{community.ecid}} <br>
        <br>
        <u>Aktuelle Mitgliedschaften</u>:<br>
        Count MPMs all: {{ community.mpmsAll.length }}<br>
        Count MPMs CREATED: {{ community.mpmsAll.filter(mpm => mpm.membershipStatus === "CREATED").length }}<br>
        Count MPMs STARTED: {{ community.mpmsAll.filter(mpm => mpm.membershipStatus === "STARTED").length }}<br>
        Count MPMs DECLINED: {{ community.mpmsAll.filter(mpm => mpm.membershipStatus === "DECLINED").length }}<br>
        Count MPMs WAITING_FOR_USER: {{ community.mpmsAll.filter(mpm => mpm.membershipStatus === "WAITING_FOR_USER").length }}<br>
        Count MPMs ACCEPTED: {{ community.mpmsAll.filter(mpm => mpm.membershipStatus === "ACCEPTED").length }}<br>
        <u>Count MPMs ACTIVE: {{ community.mpmsAll.filter(mpm => mpm.membershipStatus === "ACTIVE" || mpm.membershipStatus === "ENDING").length }}</u><br>
        Count MPMs ENDED: {{ community.mpmsAll.filter(mpm => mpm.membershipStatus === "ENDED").length }}<br>
        <br>

        <u>EnergieABhängiges Modell 2023:</u><br>
        Zählpunktmonate: {{community.mpmMonth}} <br>
        Zählpunktjahre: {{community.mpmMonth/12}} <br>
        <br>

        <u>EnergieUNabhängiges Modell: Mitgliedschaften aktiv zwischen {{ billingPeriodDateStart2024.toLocaleDateString(localeToUse) }} und {{ billingPeriodDateEnd2024.toLocaleDateString(localeToUse) }}:</u>
        <div v-for="(md, index) of community.monthData">
          {{index+1}}: {{md.timespan}} <u>{{md.countMpmActive}}</u>/{{md.countMpmActivated}}/{{md.countMpmDeactivated}}/{{md.countMpmActiveDouble}} (<u>aktiv</u>/aktiviert/deaktiviert/nicht-als-aktiv-weil-Überlappung)<br>

          <div v-if="md.mpsMultiple.length > 0">
            Zählpunkte mehrfach aktiv:<br>
            <div v-for="(mp, index) of md.mpsMultiple">
              {{mp}}<br>
            </div>
          </div>
        </div>
        <br>

        <u>EnergieABhängiges Modell: Mitgliedschaften aktiv zwischen {{ billingPeriodDateStart2023.toLocaleDateString(localeToUse) }} und {{ billingPeriodDateEnd2023.toLocaleDateString(localeToUse) }}:</u>
        <div v-for="(mpm, index) of community.mpms">
          {{index+1}}: {{mpm.meteringPoint}} {{mpm.meteringPointDirection}} ({{mpm.nameFriendly}})<br>
          &nbsp;&nbsp; {{mpm.dateStart.toLocaleDateString(localeToUse) }} >> {{mpm.dateEnd?.toLocaleDateString(localeToUse)}} <br>
          &nbsp;&nbsp; {{mpm.countMonth}} Zählpunktmonate <br>

        </div>

        <br>
      </div>




    </v-container>
  </v-container>
</template>
