import VueLogger from 'vuejs3-logger';

const isProduction = import.meta.env.NODE_ENV === 'production';

const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};


export const logger = VueLogger;
export const loggerOptions = options;

export default VueLogger;
